import React, { FC, useMemo } from 'react';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useDocumentation from '../../hooks/useDocumentation';
import logo from '../../assets/logos/smallLogo192.png';
import Button from '../../components/bootstrap/Button';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	const navigate = useNavigate();
	const { setShowDocumentation } = useDocumentation();

	const iconStyles: Partial<IButtonStyles> = {
		root: {
			color: 'secondary',
			borderColor: 'warning',
			borderSize: '1px',
			borderRadius: '50%',
			borderStyle: 'solid',
			backgroundColor: 'primary',
			i: {
				fontSize: 50,
				height: 50,
				width: 50,
				paddingTop: '1px',
				top: '5px',
				right: !asideStatus ? '-30px' : '0px',
				position: 'absolute',
			},
		},
	};

	const iconToUse = useMemo<string>(() => {
		if (asideStatus) {
			return 'IncreaseIndentArrowMirrored';
		}
		return 'IncreaseIndentArrow';
	}, [asideStatus]);

	function navigateToDashboard() {
		setShowDocumentation(false);
		navigate('/');
	};

	return (
		<div className='brand'>
			<IconButton
				iconProps={{ iconName: iconToUse }}
				styles={iconStyles}
				onClick={() => {
					setAsideStatus(!asideStatus);
				}}
			/>
			<div className='brand-logo'>
				<h1 className='brand-title '>
					<Button onClick={() => navigateToDashboard()}>
						<img
							src={logo}
							alt='logo'
							rel='apple-touch-icon'
							style={{ height: '32px' }}
						/>
					</Button>
				</h1>
			</div>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
