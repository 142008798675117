export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'ViewDashboard',
		subMenu: null,
	},
};

export const supportMenu = {
	support: {
		id: 'support',
		text: 'Support',
		icon: 'CloudSecure',
	},
	privacyPolicy: {
		id: 'privacyPolicy',
		text: 'Privacy Policy',
		path: 'support/privacy_policy',
		icon: 'ProtectedDocument',
	},
	termsAndConditions: {
		id: 'termsAndConditions',
		text: 'Ts and Cs',
		path: 'support/terms_and_conditions',
		icon: 'Dictionary',
	},
	contactUs: {
		id: 'contactUs',
		text: 'Contact Us',
		path: 'support/contact_us',
		icon: 'PublicEmail',
	},
};

export const featuresMenu = {
	features: {
		id: 'features',
		text: 'Features',
		icon: 'FavoriteStar',
	},
	reportingFeature: {
		id: 'reportingFeature',
		text: 'Reporting',
		path: 'features/report',
		icon: 'ReportDocument',
	},
	healthFeature: {
		id: 'healthFeature',
		text: 'Health',
		path: 'features/health',
		icon: 'HealthSolid',
	},
	messagesFeature: {
		id: 'messagesFeature',
		text: 'Messages',
		path: 'features/messages',
		icon: 'PostUpdate',
	},
};

export const messagesMenu = {
	serviceBus: {
		id: 'messagesMenu',
		text: 'Messages',
		icon: 'Assessment',
	},
	peekModeFeatures: {
		id: 'peekModeFeatures',
		text: 'Peek Mode',
		path: 'messages/peek-mode',
		icon: 'View',
	},
	receiveModeFeatures: {
		id: 'receiveModeFeatures',
		text: 'Receive Mode',
		path: 'messages/receive-mode',
		icon: 'Download',
	},
	messageViewFeatures: {
		id: 'messageViewFeatures',
		text: 'Message View',
		path: 'messages/messageview',
		icon: 'PostUpdate',
	},
	createMessages: {
		id: 'createMessages',
		text: 'Create Messages',
		path: 'messages/create-messages',
		icon: 'PublicEmail',
	},
	bulkProcessing: {
		id: 'bulkProcessing',
		text: 'Bulk Processing',
		path: 'messages/bulk-pocessing',
		icon: 'AddTo',
	},
	deferredProcessing: {
		id: 'deferredProcessing',
		text: 'Deferred Processing',
		path: 'messages/deferred-pocessing',
		icon: 'AlarmClock',
	},
	otherOptions: {
		id: 'otherOptions',
		text: 'Other Options',
		path: 'messages/other-options',
		icon: 'Refresh',
	},
};

export const filterMenu = {
	serviceBus: {
		id: 'filterMenu',
		text: 'Filter / Reports',
		icon: 'Assessment',
	},
	filterFeatures: {
		id: 'filterFeatures',
		text: 'Filter',
		path: 'filter/features',
		icon: 'Filter',
	},
	filterProperties: {
		id: 'filterProperties',
		text: 'Properties',
		path: 'filter/properties',
		icon: 'PageListFilter',
	},
	filterDataTypes: {
		id: 'filterDataTypes',
		text: 'Data Types',
		path: 'filter/data_types',
		icon: 'Table',
	},
	customProperties: {
		id: 'customProperties',
		text: 'Custom Properties',
		path: 'filter/custom_properties',
		icon: 'TableComputed',
	},
};

export const configurationMenu = {
	reporting: {
		id: 'configuration',
		text: 'Configuration',
		icon: 'Settings',
	},
	reportDefinitionPage: {
		id: 'configurationPage',
		text: 'Configuration',
		path: 'configuration',
		icon: 'Settings',
	},
};

export const reportingMenu = {
	reporting: {
		id: 'reporting',
		text: 'Reporting',
		icon: 'FabricFormLibrary',
	},
	reportDefinitionPage: {
		id: 'reportListPage',
		text: 'List',
		path: 'report/list',
		icon: 'BulletedList',
	},
	reportCreateEdit: {
		id: 'reportCreateEdit',
		text: 'Create / Edit',
		path: 'report/create_edit',
		icon: 'ReportAdd',
	},
};

export const serviceBusMenu = {
	reporting: {
		id: 'serviceBusMenu',
		text: 'Service Bus',
		icon: 'FabricFormLibrary',
	},
	serviceBusList: {
		id: 'serviceBusList',
		text: 'Bus List',
		path: 'service_bus/list',
		icon: 'BulletedList',
	},
	serviceBusDefinition: {
		id: 'serviceBusDefinition',
		text: 'Bus Definition',
		path: 'service_bus/bus_definition',
		icon: 'Edit',
	},
	serviceBusQueueList: {
		id: 'serviceBusQueueList',
		text: 'Queue List',
		path: 'service_bus/queue_list',
		icon: 'BulletedList',
	},
	serviceBusQueueDefinition: {
		id: 'serviceBusQueueDefinition',
		text: 'Queue Definition',
		path: 'service_bus/queue_definition',
		icon: 'BuildQueue',
	},
	serviceBusAssociation: {
		id: 'serviceBusAssociation',
		text: 'Report Association',
		path: 'service_bus/association',
		icon: 'AddLink',
	},
	serviceBusAssociationCreateEdit: {
		id: 'serviceBusAssociationCreateEdit',
		text: 'Associated Create / Edit',
		path: 'service_bus/create_edit',
		icon: 'AddLink',
	},
};

export const healthRulesMenu = {
	healthRules: {
		id: 'healthRules',
		text: 'Health Rules',
		icon: 'FabricFormLibrary',
	},
	healthRuleList: {
		id: 'healthRuleList',
		text: 'Health Rule List',
		path: 'health/list',
		icon: 'BulletedList',
	},
	healthRuleServiceBusAssociation: {
		id: 'healthRuleServiceBusAssociation',
		text: 'Rule Association',
		path: 'health/association',
		icon: 'AddLink',
	},
	HealthRuleColorList: {
		id: 'HealthRuleColorList',
		text: 'Rule Colors',
		path: 'health/color',
		icon: 'Highlight',
	},
	healthRuleDefinitionPage: {
		id: 'healthRuleDefinitionPage',
		text: 'Rule Definition',
		path: 'health/definition',
		icon: 'Edit',
	},
};

export const auditingMenu = {
	reporting: {
		id: 'auditingMenu',
		text: 'Auditing',
		icon: 'FabricFormLibrary',
	},
	auditingBus: {
		id: 'auditingBus',
		text: 'Service Bus',
		path: 'auditing/service_bus',
		icon: 'ComplianceAudit',
	},
	auditingSite: {
		id: 'auditingSite',
		text: 'Site',
		path: 'auditing/site_auditing',
		icon: 'ComplianceAudit',
	},
	reportSite: {
		id: 'auditingStats',
		text: 'Statistics',
		path: 'auditing/stats_auditing',
		icon: 'TableComputed',
	},
};

export const installationMenu = {
	installation: {
		id: 'installation',
		text: 'Installation',
		icon: 'Assessment',
	},
	installationPage: {
		id: 'installationPage',
		text: 'Install SBM',
		path: 'list-pages',
		icon: 'Download',
		subMenu: {
			beforeStarting: {
				id: 'beforeStarting',
				text: 'Before Starting',
				path: 'install/before',
				icon: 'ChevronLeft',
			},
			managedAppInstall: {
				id: 'managedAppInstall',
				text: 'Install',
				path: 'install/install',
				icon: 'Download',
			},
			managedAppAfterInstall: {
				id: 'managedAppAfterInstall',
				text: 'After Install',
				path: 'install/after',
				icon: 'ChevronRight',
			},
			reportingAfterInstall: {
				id: 'reportingAfterInstall',
				text: 'Reporting Roles',
				path: 'install/monitoring',
				icon: 'DoubleChevronRight',
			},
			gettingStarted: {
				id: 'gettingStarted',
				text: 'Initialise',
				path: 'install/gettingStarted',
				icon: 'ArrowUpRight',
			},
		},
	},
	viewInstallation: {
		id: 'viewInstallation',
		text: 'View installation',
		path: 'install/viewInstallation',
		icon: 'EntryView',
	},
};
