import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu } from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};

const APP = {
	FEATURE: {
		GRID: lazy(() => import('../pages/presentation/dashboard/feature/FeatureGridPage')),
		VIEW: lazy(() => import('../pages/presentation/dashboard/feature/FeatureViewPage')),
	},
	FEATURES: {
		REPORT: lazy(() => import('../pages/presentation/features/ReportFeaturePage')),
		HEALTH: lazy(() => import('../pages/presentation/features/HealthFeaturePage')),
		MESSAGE: lazy(() => import('../pages/presentation/features/MessageFeaturePage')),
	},
	MESSAGE: {
		MESSAGE_VIEW: lazy(() => import('../pages/presentation/messages/MessageViewPage')),
		PEEK_MODE: lazy(() => import('../pages/presentation/messages/MessagePeekModePage')),
		RECEIVE_MODE: lazy(
			() => import('../pages/presentation/messages/MessageReceiveModePage'),
		),
		CREATE_MESSAGES: lazy(() => import('../pages/presentation/messages/CreateMessagesPage')),
		BULK_PROCESSING: lazy(() => import('../pages/presentation/messages/BulkProcessingPage')),
		DEFERRED_PROCESSING: lazy(() => import('../pages/presentation/messages/DeferredProcessingPage')),
		OTHER_OPTIONS: lazy(() => import('../pages/presentation/messages/OtherOptionsPage')),
	},
	FILTER: {
		PROPERTIES: lazy(() => import('../pages/presentation/filter/FilterPropertiesPage')),
		DATA_TYPES: lazy(() => import('../pages/presentation/filter/FilterDataTypesPage')),
		FEATURES: lazy(() => import('../pages/presentation/filter/MessageFilterPage')),
		CUSTOM_PROPERTIES: lazy(() => import('../pages/presentation/filter/CustomPropertiesPage')),
	},
	CONFIGURATION: {
		CONFIG: lazy(() => import('../pages/presentation/configuration/ConfigurationPage')),
	},
	REPORT: {
		REPORT_LIST: lazy(
			() => import('../pages/presentation/reporting/ReportListPage'),
		),
		REPORT_EDIT_CREATE: lazy(
			() => import('../pages/presentation/reporting/ReportCreateEditPage'),
		),
	},
	BUS: {
		BUS_LIST: lazy(() => import('../pages/presentation/service-bus/ServiceBusListPage')),
		BUS_DEFINITION: lazy(() => import('../pages/presentation/service-bus/ServiceBusDefinitionPage')),
		QUEUE_LIST: lazy(() => import('../pages/presentation/service-bus/ServiceBusQueueListPage')),
		QUEUE_DEFINITION: lazy(() => import('../pages/presentation/service-bus/ServiceBusQueueDefinitionPage')),
		ASSOCIATION: lazy(
			() => import('../pages/presentation/service-bus/ServiceBusAssociationPage'),
		),
		CREATE_EDIT: lazy(() => import('../pages/presentation/service-bus/ServiceBusAssociationCreateEditPage')),
	},
	HEALTH: {
		HEALTH_LIST: lazy(() => import('../pages/presentation/health/HealthRuleListPage')),
		HEALTH_ASSOCIATION: lazy(
			() => import('../pages/presentation/health/HealthAssociationPage'),
		),
		HEALTH_COLOR_LIST: lazy(() => import('../pages/presentation/health/HealthRuleColorList')),
		HEALTH_DEFINITION: lazy(() => import('../pages/presentation/health/HealthDefinitionPage')),
	},
	AUDIT: {
		BUS: lazy(() => import('../pages/presentation/auditing/BusAuditPage')),
		SITE: lazy(() => import('../pages/presentation/auditing/SiteAuditPage')),
		STATISTICS: lazy(() => import('../pages/presentation/auditing/PerformanceAuditPage')),
	},
	
	INSTALL: {
		INSTALL_BEFORE: lazy(() => import('../pages/presentation/installation/BeforeInstallPage')),
		INSTALL: lazy(() => import('../pages/presentation/installation/InstallPage')),
		INSTALL_AFTER: lazy(() => import('../pages/presentation/installation/AfterInstallPage')),
		INSTALL_REPORTING: lazy(() => import('../pages/presentation/installation/AfterInstallReportingPage')),
		INSTALL_VIEW_INSTALLATION: lazy(() => import('../pages/presentation/installation/ViewInstallationPage')),
		INSTALL_GETTING_STARTED: lazy(() => import('../pages/presentation/installation/GettingStartedPage')),
	},
	SUPPORT: {
		PRIVACY_POLICY: lazy(() => import('../pages/presentation/support/PrivacyPolicyPage')),
		TERMS_AND_CONDITIONS: lazy(() => import('../pages/presentation/support/TermsAndConditionsPage')),
		CONTACT_US: lazy(() => import('../pages/presentation/support/ContactUsPage')),
	},
};

const features: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: '/index.html',
		element: <LANDING.DASHBOARD />,
	},
	
	/**
	 * App > Feature
	 */
	{
		path: 'feature/grid',
		element: <APP.FEATURE.GRID />,
	},
	{
		path: `feature/item/:id`,
		element: <APP.FEATURE.VIEW />,
	},

	/**
	 * Features
	 */
	{
		path: `features/report`,
		element: <APP.FEATURES.REPORT />,
	},
	{
		path: `features/health`,
		element: <APP.FEATURES.HEALTH />,
	},
	{
		path: `features/messages`,
		element: <APP.FEATURES.MESSAGE />,
	},

	/**
	 * Support
	 */
	{
		path: `support/privacy_policy`,
		element: <APP.SUPPORT.PRIVACY_POLICY />,
	},
	{
		path: `support/terms_and_conditions`,
		element: <APP.SUPPORT.TERMS_AND_CONDITIONS />,
	},
	{
		path: `support/contact_us`,
		element: <APP.SUPPORT.CONTACT_US />,
	},
];

const documentation: RouteProps[] = [
	/**
	 * Messages
	 */
	{
		path: `messages/messageview`,
		element: <APP.MESSAGE.MESSAGE_VIEW />,
	},
	{
		path: 'messages/peek-mode',
		element: <APP.MESSAGE.PEEK_MODE />,
	},
	{
		path: 'messages/receive-mode',
		element: <APP.MESSAGE.RECEIVE_MODE />,
	},
	{
		path: 'messages/create-messages',
		element: <APP.MESSAGE.CREATE_MESSAGES />,
	},
	{
		path: 'messages/bulk-pocessing',
		element: <APP.MESSAGE.BULK_PROCESSING />,
	},
	{
		path: 'messages/deferred-pocessing',
		element: <APP.MESSAGE.DEFERRED_PROCESSING />,
	},
	{
		path: 'messages/other-options',
		element: <APP.MESSAGE.OTHER_OPTIONS />,
	},

	/**
	 * Filter properties
	 */
	{
		path: 'filter/features',
		element: <APP.FILTER.FEATURES />,
	},
	{
		path: `filter/properties`,
		element: <APP.FILTER.PROPERTIES />,
	},
	{
		path: `filter/data_types`,
		element: <APP.FILTER.DATA_TYPES />,
	},
	{
		path: `filter/custom_properties`,
		element: <APP.FILTER.CUSTOM_PROPERTIES />,
	},

	/**
	 * Configuration
	 */
	{
		path: `configuration`,
		element: <APP.CONFIGURATION.CONFIG />,
	},

	/**
	 * Reports
	 */
	{
		path: `report/list`,
		element: <APP.REPORT.REPORT_LIST />,
	},
	{
		path: `report/create_edit`,
		element: <APP.REPORT.REPORT_EDIT_CREATE />,
	},

	/**
	 * Service Bus
	 */
	{
		path: `service_bus/list`,
		element: <APP.BUS.BUS_LIST />,
	},
	{
		path: `service_bus/bus_definition`,
		element: <APP.BUS.BUS_DEFINITION />,
	},
	{
		path: `service_bus/queue_list`,
		element: <APP.BUS.QUEUE_LIST />,
	},
	{
		path: `service_bus/queue_definition`,
		element: <APP.BUS.QUEUE_DEFINITION />,
	},
	{
		path: `service_bus/association`,
		element: <APP.BUS.ASSOCIATION />,
	},
	{
		path: `service_bus/create_edit`,
		element: <APP.BUS.CREATE_EDIT />,
	},

	/**
	 * Health
	 */
	{
		path: `health/list`,
		element: <APP.HEALTH.HEALTH_LIST />,
	},
	{
		path: `health/association`,
		element: <APP.HEALTH.HEALTH_ASSOCIATION />,
	},
	{
		path: `health/color`,
		element: <APP.HEALTH.HEALTH_COLOR_LIST />,
	},
	{
		path: `health/definition`,
		element: <APP.HEALTH.HEALTH_DEFINITION />,
	},


	/**
	 * Auditing
	 */
	{
		path: `auditing/service_bus`,
		element: <APP.AUDIT.BUS />,
	},
	{
		path: `auditing/site_auditing`,
		element: <APP.AUDIT.SITE />,
	},
	{
		path: `auditing/stats_auditing`,
		element: <APP.AUDIT.STATISTICS />,
	},

	/**
	 * Install
	 */
	{
		path: `install/before`,
		element: <APP.INSTALL.INSTALL_BEFORE />,
	},
	{
		path: `install/install`,
		element: <APP.INSTALL.INSTALL />,
	},
	{
		path: `install/after`,
		element: <APP.INSTALL.INSTALL_AFTER />,
	},
	{
		path: `install/monitoring`,
		element: <APP.INSTALL.INSTALL_REPORTING />,
	},
	{
		path: `install/viewInstallation`,
		element: <APP.INSTALL.INSTALL_VIEW_INSTALLATION />,
	},
	{
		path: `install/gettingStarted`,
		element: <APP.INSTALL.INSTALL_GETTING_STARTED />,
	},
];

const contents = [...features, ...documentation];

export default contents;
