import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { dashboardPagesMenu, supportMenu, featuresMenu, messagesMenu, filterMenu, configurationMenu, reportingMenu, serviceBusMenu, healthRulesMenu, auditingMenu, installationMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';

const DefaultHeader = () => {
	const { width } = useDeviceScreen();
	const location = useLocation();
	const [ selectedMenu, setSelectedMenu ]= useState<any>(dashboardPagesMenu);

	useEffect(() => {
		if(location.pathname.indexOf('support') >= 0) {
			setSelectedMenu(supportMenu);
		}
		else if(location.pathname.indexOf('features/') >= 0) {
			setSelectedMenu(featuresMenu);
		}
		else if(location.pathname.indexOf('messages') >= 0) {
			setSelectedMenu(messagesMenu);
		}
		else if(location.pathname.indexOf('filter/') >= 0) {
			setSelectedMenu(filterMenu);
		}
		else if(location.pathname.indexOf('configuration') >= 0) {
			setSelectedMenu(configurationMenu);
		}
		else if(location.pathname.indexOf('report') >= 0) {
			setSelectedMenu(reportingMenu);
		}
		else if(location.pathname.indexOf('service_bus/') >= 0) {
			setSelectedMenu(serviceBusMenu);
		}
		else if(location.pathname.indexOf('health') >= 0) {
			setSelectedMenu(healthRulesMenu);
		}
		else if(location.pathname.indexOf('auditing') >= 0) {
			setSelectedMenu(auditingMenu);
		}
		else if(location.pathname.indexOf('install') >= 0) {
			setSelectedMenu(installationMenu);
		}

	}, [location.pathname]);

	return (
		<Header>
			<HeaderLeft>
				<Navigation
					menu={{ ...selectedMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
