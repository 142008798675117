import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { IStackItemStyles } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';

import {
	dashboardPagesMenu,
	configurationMenu,
	reportingMenu,
	healthRulesMenu,
	messagesMenu,
	serviceBusMenu,
	auditingMenu,
	filterMenu,
	installationMenu,
	supportMenu,
	featuresMenu,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import useDocumentation from '../../../hooks/useDocumentation';

const DefaultAside = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const location = useLocation();

	const { showDocumentation, setShowDocumentation } = useDocumentation();
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [ areOnFeaturesView, setAreOnFeaturesView ]= useState<boolean>(true);
	const [ toggleOnText, setToggleOnText ]= useState<string>(t('menu:Documentation'));
	const [ toggleOffText, setToggleOffText ]= useState<string>(t('menu:Features'));

	const toggleStyle : IStackItemStyles = {
		root: {
			paddingTop: '1rem',
			fontSize: '24px',
			color: 'white',
			label: {
				paddingLeft: '1rem',
				color: 'white'
			}
		}
	};

	useEffect(() => {
		setAreOnFeaturesView(location.pathname.length < 2 || location.pathname.indexOf('support') >= 0 || location.pathname.indexOf('features/') >= 0 || location.pathname.indexOf('marketplace') >= 0 || location.pathname.indexOf('install') >= 0);

	}, [location.pathname]);

	useEffect(() => {
		// Detect when on the dashboard or a support page
		if (areOnFeaturesView && showDocumentation) {
			// We are on a 'Features' page. Make sure we are not showing documentation
			setShowDocumentation(false);

		}
		else if (!areOnFeaturesView && !showDocumentation) {
			// We are currently showing 'Features'. However, we are on a 'Documentation' page. 
			// Set showDocumentation to true
			setShowDocumentation(true);
		}

	}, [areOnFeaturesView]);

	useEffect(() => {
		if (asideStatus) {
			setToggleOnText(t('menu:Documentation'));
			setToggleOffText(t('menu:Features'));
		}
		else {
			setToggleOnText('');
			setToggleOffText('');
		}

	}, [asideStatus]);

	// const shoppingCartIcon: IIconProps = { iconName: 'ShoppingCart' };

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{!showDocumentation && (
					<>
						<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
						{
							/*
						<DefaultButton iconProps={shoppingCartIcon} href="https://portal.azure.com/#view/Microsoft_Azure_Marketplace/MarketplaceOffersBlade/selectedMenuItemId/home" 
							target="_blank" title="Azure Marketplace" disabled={false} styles={buttonStyle} className='navigation-link navigation-link-pill active'>
							{ marketplaceText }
						</DefaultButton>
						*/
						}
						<NavigationLine />
						<Navigation menu={featuresMenu} id='aside-features' />
						<NavigationLine />
						<Navigation menu={installationMenu} id='aside-installation' />
						<NavigationLine />
						<Navigation menu={supportMenu} id='aside-support' />
					</>
				)}

				{showDocumentation && (
					<>
						<Navigation menu={messagesMenu} id='aside-servicebusfeatures' />
						<NavigationLine />
						<Navigation menu={filterMenu} id='aside-filterproperties' />
						<NavigationLine />
						<Navigation menu={configurationMenu} id='aside-configuration' />
						<NavigationLine />
						<Navigation menu={reportingMenu} id='aside-reporting' />
						<NavigationLine />
						<Navigation menu={serviceBusMenu} id='aside-bus' />
						<NavigationLine />
						<Navigation menu={healthRulesMenu} id='aside-health' />
						<NavigationLine />
						<Navigation menu={auditingMenu} id='aside-auditing' />
						<NavigationLine />
					</>
				)}

			</AsideBody>
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
					<div
						role='presentation'
						className='navigation-item cursor-pointer'
						onClick={() => {
							setShowDocumentation(!showDocumentation);
						}}
						data-tour='documentation'>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Toggle styles={toggleStyle} onText={toggleOnText} offText={toggleOffText} checked={showDocumentation} 
										onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => { setShowDocumentation(!!checked) }}
										role="checkbox" />
								</span>
							</span>
						</div>
					</div>
				</nav>
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
