import React, { FC, ReactNode, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTour } from '@reactour/tour';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [ selectedVersion, setSelectedVersion ]= useState<string>('version 1.0');

	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
		style: {
			marginLeft: '1rem',
		}
	};

	const { i18n } = useTranslation();

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	const { setIsOpen } = useTour();

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/* Dark Mode */}
				<div className='col-auto d-flex align-items-center'>
					<Popovers trigger='hover' desc='Select Service Bus Management version'>
						<Dropdown isButtonGroup>
							<Button color='success' isLight icon='WaterfallChart' data-tour='version-tour'>
								{ selectedVersion }
							</Button>
							<DropdownToggle>
								<Button color='success' isLight isVisuallyHidden />
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd>
								<DropdownItem>
									<Button onClick={() => setSelectedVersion('version 1.0')}>
										version 1.0
									</Button>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</Popovers>
					<Popovers trigger='hover' desc='Start the "SBM" tour'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setIsOpen(true)}
							className='btn btn-primary'
							color={darkModeStatus ? 'info' : 'warning'}
							aria-label='Start the "SBM" tour'>
							<Icon
								iconName='Airplane'
								ariaLabel=''
								color={darkModeStatus ? 'white' : 'blue'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn btn-primary'
							data-tour='dark-mode'
							color={darkModeStatus ? 'info' : 'warning'}
							aria-label='Toggle dark mode'>
							<Icon
								iconName={darkModeStatus ? 'ClearNight' : 'Sunny'}
								ariaLabel=''
								color={darkModeStatus ? 'white' : 'blue'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{afterChildren}
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
