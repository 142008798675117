import React, { useEffect, useState } from 'react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';

interface IIconProps {
	iconName: string;
	ariaLabel?: string;
	styles?: string;
	className?: string;
	color?: string;
	size?: number;
}

// Merge default icon styles with any supplied styles
const initialStyles = mergeStyleSets({
	fontSize: '25px',
	height: '25px',
	width: '25px',
	margin: '0px 0px',
});

const Icon = (props: IIconProps) => {
	const [ finalStyles, setFinalStyles ] = useState<string>('');

	if(finalStyles === '')
		setFinalStyles(mergeStyles(initialStyles));
	
	useEffect(() => {
		if(!props.color) return;
		let styles = mergeStyles({ color: props.color }, initialStyles); // props.color ? props.color : 'primary'
		//console.log('Props.color changed: ' + props.color + '. styles: ' + styles);
		setFinalStyles(styles);

	}, [props.color]);

	return (
		<span>
			{ !!props.iconName && (
				<FontIcon
					aria-label={props?.ariaLabel ?? 'Icon'}
					iconName={props.iconName}
					className={finalStyles}
				/>
			)}
		</span>
	);
};

export default Icon;